<template>
  <v-row class="pa-3">
    <v-col sm="8" md="8">
      <!--Sales order Reference-->
      <v-text-field
        :value="value"
        label="Order #"
        readonly
        outlined
        dense
        color="#3d2cdd"
        class="font-weight-bold"
        :loading="loading"
      >
      </v-text-field>
    </v-col>
    <v-col sm="4" md="4">
      <!--Quote reference-->
      <v-text-field
        :value="quote_ref"
        label="Quote Ref #"
        readonly
        outlined
        dense
        color="#3d2cdd"
        :loading="loading"
      >
      </v-text-field>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "OrderNumberInput",
  props: ["value", "quote_ref", "loading"],
  data() {
    return {};
  },
};
</script>